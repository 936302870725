exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instantseo-js": () => import("./../../../src/pages/instantseo.js" /* webpackChunkName: "component---src-pages-instantseo-js" */),
  "component---src-pages-projects-custom-autocomplete-material-ui-v-4-jsx": () => import("./../../../src/pages/projects/custom-autocomplete-material-ui-v4.jsx" /* webpackChunkName: "component---src-pages-projects-custom-autocomplete-material-ui-v-4-jsx" */),
  "component---src-pages-projects-gatsby-photo-timeline-jsx": () => import("./../../../src/pages/projects/gatsby-photo-timeline.jsx" /* webpackChunkName: "component---src-pages-projects-gatsby-photo-timeline-jsx" */),
  "component---src-pages-projects-gatsby-theme-budapest-jsx": () => import("./../../../src/pages/projects/gatsby-theme-budapest.jsx" /* webpackChunkName: "component---src-pages-projects-gatsby-theme-budapest-jsx" */),
  "component---src-pages-projects-nextjs-serviciiromania-jsx": () => import("./../../../src/pages/projects/nextjs-serviciiromania.jsx" /* webpackChunkName: "component---src-pages-projects-nextjs-serviciiromania-jsx" */),
  "component---src-pages-projects-react-spring-cards-jsx": () => import("./../../../src/pages/projects/react-spring-cards.jsx" /* webpackChunkName: "component---src-pages-projects-react-spring-cards-jsx" */)
}

